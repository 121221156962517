.screener-filter-applied {
    .MuiTabs-root {
        display: none !important;
    }

    div[role="tabpanel"] {
        display: block !important;
    }

    .showOverFlow {
        overflow-y: visible;
    }

    .hideSection {
        display: none !important;
    }

    .MuiPaper-root {
        display: none !important;
    }
    ::-webkit-scrollbar {
        width: 15px;
    }
    ::-webkit-scrollbar-button {
        height: 0px;
    }
    ::-webkit-scrollbar-thumb {
        min-height: 51%;
        border: 4px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        background-color: #bdbdbd;
        border-radius: 25px;
    }
}